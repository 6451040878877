import { render, staticRenderFns } from "./simpleComparePricesTable.vue?vue&type=template&id=4b9d7480&scoped=true"
import script from "./simpleComparePricesTable.vue?vue&type=script&lang=js"
export * from "./simpleComparePricesTable.vue?vue&type=script&lang=js"
import style0 from "./simpleComparePricesTable.vue?vue&type=style&index=0&id=4b9d7480&prod&scoped=true&lang=css"
import style1 from "./simpleComparePricesTable.vue?vue&type=style&index=1&id=4b9d7480&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b9d7480",
  null
  
)

export default component.exports