export default function offersHandler(productItem) {
  if (productItem && productItem.product) {
    let country = JSON.parse(localStorage.user_website).country
    let offer = productItem.product.offers.length ? productItem.product.offers[0].price : 0

    let lastChangedOffer = 0

    /**
     * If last changed price greater than 0 then we use it, if not we use the previous price
     */
    if (productItem.product.offers.length) {
      const lastChangedPrice = productItem.product.offers[0].lastChangedPrice
      lastChangedOffer = lastChangedPrice
        ? lastChangedPrice
        : productItem.product.offers[0].previousPrice
    }

    let offer_html = { ...offer }
    let offer_previous_html = { ...lastChangedOffer }

    switch (country) {
      case 'mx':
        offer_html = new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
        }).format(offer)

        offer_previous_html = new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
        }).format(lastChangedOffer)

        break
      case 'es':
        offer_html = new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR',
        }).format(offer)

        offer_previous_html = new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR',
        }).format(lastChangedOffer)
        break
      case 'uk':
        offer_html = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP',
        }).format(offer)

        offer_previous_html = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP',
        }).format(lastChangedOffer)

        break
      case 'do':
        offer_html = new Intl.NumberFormat('es-DO', {
          style: 'currency',
          currency: 'DOP',
        }).format(offer)

        offer_previous_html = new Intl.NumberFormat('es-DO', {
          style: 'currency',
          currency: 'DOP',
        }).format(lastChangedOffer)

        break
      default:
        offer_html = new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR',
        }).format(offer)

        offer_previous_html = new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR',
        }).format(lastChangedOffer)
    }

    offer_html = `${offer_html}`
    offer_previous_html = `${offer_previous_html}`

    return {
      offer_value: offer,
      offer_previous_value: lastChangedOffer,
      offer_html,
      offer_previous_html,
    }
  } else {
    return {
      offer_value: 0,
      offer_html: '',
    }
  }
}
