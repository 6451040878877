import moment from 'moment'
export default function formatProductOffer(offer, match, websiteAlias) {
  let html = ''
  let country = JSON.parse(localStorage.user_website).country
  let object = {
    price: offer && offer.price ? offer.price : '',
    status: '',
    statusStyle: 'text--disabled',
    promotion: '',
    availabilityStatus: '',
    availabilityStatusStyle: '',
  }

  if (object.price) {
    switch (country) {
      case 'mx':
        object.price = new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
        }).format(object.price)
        break
      case 'es':
        object.price = new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR',
        }).format(object.price)
        break
      case 'uk':
        object.price = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP',
        }).format(object.price)
        break
      case 'do': //dominican republic
        object.price = new Intl.NumberFormat('es-DO', {
          style: 'currency',
          currency: 'DOP',
        }).format(object.price)
        break
      default:
        object.price = new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR',
        }).format(object.price)
    }
  }

  if (offer && offer.availability && object.price) {
    switch (offer.availability) {
      case 'InStock':
        object.availabilityStatus = 'S'
        object.availabilityStatusStyle = 'success--text'
        break
      case 'OutOfStock':
        object.availabilityStatus = 'S'
        object.availabilityStatusStyle = 'error--text'
        break
      default:
        object.availabilityStatus = ''
        object.availabilityStatusStyle = 'text--secondary'
    }
  }

  if (offer && offer.price) {
    const offerUpdateDate = moment(offer.updateDate).format('YYYY-MM-DD')
    const offerChangeDate = moment(offer.changeDate).format('YYYY-MM-DD')
    const dateToCompareWith =
      moment().diff(moment(offer.updateDate), 'days') <= 1
        ? moment(offer.updateDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD')

    if (offerUpdateDate == dateToCompareWith) {
      if (offer.promotion) object.promotion = 'P'
      if (offerChangeDate == dateToCompareWith) {
        switch (offer.status) {
          case 'down':
            object.status = '&darr;'
            object.statusStyle = 'success--text'
            object.statusText = 'Down'
            break
          case 'up':
            object.status = '&uarr;'
            object.statusStyle = 'error--text'
            object.statusText = 'Up'
            break
        }
      } else {
        object.statusText = 'No change'
        object.status = '='
      }
    } else if (offer && offer.price) object.price = `${object.price} -`
  }

  if (match[websiteAlias] && match[websiteAlias].id && !object.price) {
    object.statusText = 'No price'
    object.price = '-'
  }

  html += `<span class="text--secondary font-weight-bold sm-text">${object.price}</span><br>`

  html += `<span class="font-weight-bold sm-text ${object.statusStyle} mr-1 tooltip">${object.status}
    <span class="tooltiptext">${object.statusText}</span>
  </span>`

  if (offer && offer.availability && object.price) {
    html += `<span class="font-weight-bold sm-text ${object.availabilityStatusStyle} mr-1 tooltip">${object.availabilityStatus}
  <span class="tooltiptext">${offer.availability}</span>
  </span>`
  }

  if (object.promotion)
    html += `<span class="font-weight-bold sm-text text--secondary mr-1 tooltip">${object.promotion}
  <span class="tooltiptext">Promotion</span>
  </span>`

  if (match[websiteAlias] && match[websiteAlias].isGoogleShoppingProduct)
    html += `<span class="font-weight-bold sm-text text--secondary mr-1 tooltip"><a href="https://www.google.com${match.googleShoppingUrl}?gl=${JSON.parse(localStorage.user_website).country}" target="_blank" class="text--secondary">G</a><span class="tooltiptext">Google shopping price</span></span>`
  //html += `</div>`
  return html
}
