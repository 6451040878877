<template>
  <div v-if="product.product">
    <div v-if="!show_delete && !removed">
      <div class="product-website-text d-flex align-center">
        <div class="image-container d-flex justify-center">
          <img class="product-image" :src="product.product.image" />
        </div>
        <div>
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="d-inline-block text-truncate secondary--text sm-text"
                  style="max-width: 400px"
                >
                  {{ product.product.name }}
                </span>
              </template>
              <span>{{ product.product.name }}</span>
            </v-tooltip>
          </div>
          <div v-if="product.product.brand" class="font-weight-medium text-truncate sm-text">
            {{ product.product.brand.toUpperCase() }}
          </div>
        </div>
        <v-btn icon class="ml-auto" v-if="!default_product" @click="show_delete = true">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <v-text-field
        label="New product url"
        v-model="product.product.url"
        :background-color="background"
        :color="color"
        :rules="urlRule"
        solo
        hide-details
        clearable
        @input="valueChanged()"
      ></v-text-field>
    </div>
    <div v-if="show_delete">
      <div class="product-website-text">
        <div class="d-flex align-center">
          <div class="image-container d-flex justify-center">
            <img class="product-image" :src="product.product.image" />
          </div>
          {{ product.product.brand }}
        </div>
        <div v-if="removed">
          <div>
            <v-alert type="info" class="mb-0 mt-4 mb-4 font-weight-bold delete-text">
              <div>
                The deletion will applied when the edit is complete, you still can undo the deletion
              </div>
              <div>{{ product.product.url }}</div>
            </v-alert>
          </div>
          <div class="text-center mt-4">
            <v-btn color="primary" @click="undoDeletion(product.product)">Undo</v-btn>
          </div>
        </div>
        <div v-else>
          <div>
            <v-alert type="warning" class="mb-0 mt-4 mb-4 font-weight-bold delete-text">
              <div>
                Are you sure you want to delete "
                {{ product.website.alias }} " product ?
              </div>
              <div>{{ product.product.url }}</div>
            </v-alert>
          </div>
          <div class="text-center mt-4">
            <v-btn class="mr-2" color="primary" text @click="deleteProduct(product.product)"
              >Yes, delete it!</v-btn
            >
            <v-btn color="error" @click="show_delete = false">cancel</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  const isUrl = require('is-url')
  export default {
    name: 'editProductForm',
    props: ['product', 'default_product'],
    data() {
      return {
        removed: false,
        show_delete: false,
        submit_loading: false,
        background: '#ECFBE6',
        color: '#0BA058',
        urlRule: [(v) => isUrl(v) || 'This url not valid, please try to correct it'],
      }
    },
    methods: {
      deleteProduct() {
        this.removed = true
        this.$emit('delete-product', this.product)
      },
      undoDeletion() {
        this.removed = false
        this.show_delete = false
        this.$emit('undo-deletion-product', this.product)
      },
      validateUrl(url) {
        try {
          // Create a new URL object, which will throw an error if the URL is invalid
          let parsedUrl = URL.parse(url)

          console.log(parsedUrl)

          if (parsedUrl.host == null) return false

          if (parsedUrl.protocol == null) return false

          if (parsedUrl.hostname == null) return false

          //should have a domain suffix (e.g. .com, .org, .net, etc.)
          if (parsedUrl.hostname.split('.').length < 2) return false

          // URL constructor will handle spaces, but we should normalize and check if it's a valid URL
          return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:'
        } catch (error) {
          // If URL constructor throws an error, the URL is invalid
          return false
        }
      },
      valueChanged() {
        if (this.validateUrl(this.product.product.url)) {
          this.background = '#ECFBE6'
          this.color = '#0BA058'
        } else {
          this.background = '#FFE9ED'
          this.color = '#CC1E3F'
        }
      },
    },
  }
</script>
<style scoped>
  .product-website-text {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  .url_input {
    color: #ff0000;
    border-color: #ff0000;
  }
  .costum-bagde {
    text-transform: none;
    cursor: auto;
  }
  .product-name-text {
    font-size: 1.2rem;
  }
  .product-image {
    object-fit: contain;
    max-width: 200px;
    max-height: 80px;
    background-size: cover;
  }
  .image-container {
    width: 100px;
  }
</style>
